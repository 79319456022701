<template lang="pug">
v-container(
  fill-height,
  fluid
)
  v-layout(
    align-center,
    justify-center
  )
    v-flex.about-text.text-xs-center.xs10(
      align-content-center,
      shrink
    )
      //- p.text-center.display-1.mb-1.pb-1 !
      //- a(
      //-   :href="link",
      //-   @click="randomLink",
      //-   rel="noopener",
      //-   target="_blank"
      //- )
      img.bear.text-xs-center.mt-2.pt-2(
        :src="$options.polandBall",
        alt="Happy Polandball",
        style="margin-left: auto; margin-right: auto; display: flex"
      )
      p.text-center.subtitle-1.mt-4.pt-4 This is a proof-of-concept for a vocabulary trainer tool utilizing the internal pester corporate translation glossary.
      p.text-center.subtitle-1.mt-2.pt-2 Click / Tap the card to reveal the word's translation, and again to load the next word.
      p.text-center.subtitle-1.mt-2.pt-2 With modern browsers, you can hear the word's pronounciation.
      //- p.text-center.subtitle-1.mt-2.pt-2 Finally, you can use the card settings to sort or shuffle the word order, or skip to specific points in the word list.
</template>
<script>
import polandBall from '@/assets/pester-pac-automation.webp'
export default {
  data: () => ({
    link: null,
  }),
  created() {
    // this.randomLink()
  },
  methods: {
    // randomLink() {
    //   this.link = this.sample(this.$options.links.filter(l => l !== this.link))
    // },
    sample(arr) {
      return arr[Math.floor(Math.random() * arr.length)]
    },
  },
  polandBall,
  // links: [
  //   `https://www.youtube.com/watch?v=oOfroqtzQRM`, // Firecracker
  //   `https://www.youtube.com/watch?v=DLXTEOHvryA`, // Małolatki
  //   `https://www.youtube.com/watch?v=jAa4BOwHdRI`, // Ale Ale Aleksandra
  // ],
}
</script>
